<template>
  <div class="block">
    <span @click="dialogFormVisible = true">显示</span>

    <el-dialog title="货品信息" :visible.sync="dialogFormVisible">
      <el-tabs v-model="activeName"  type="border-card">
          <el-tab-pane label="基本信息" name="first">
            <table>
              <tr>
                <td class="title">货品编码</td>
                <td class="value">6-01102-11060009</td>
              </tr>
              <tr>
                <td class="title">货品名称</td>
                <td class="value">线束</td>
              </tr>
              <tr>
                <td class="title">货品规格</td>
                <td class="value">地线/常规 18AWG/单层绝缘/105℃/600V//黄绿色/PVC/1015/L220MM/</td>
              </tr>
              <tr>
                <td class="title">货品分类</td>
                <td class="value">线材</td>
              </tr>
              <tr>
                <td class="title">销售单价</td>
                <td class="value">2</td>
              </tr>
              <tr>
                <td class="title">成本单价</td>
                <td class="value">1</td>
              </tr>
              <tr>
                <td class="title">计量单位</td>
                <td class="value">PCS</td>
              </tr>
              <tr>
                <td class="title">货品简介</td>
                <td class="value">简介信息</td>
              </tr>
              <tr>
                <td class="title">总库存</td>
                <td class="value">2000</td>
              </tr>
              <tr>
                <td class="title">可用库存</td>
                <td class="value">1000</td>
              </tr>
              <tr>
                <td class="title">订单占用库存</td>
                <td class="value">200</td>
              </tr>
              <tr>
                <td class="title">采购在途库存</td>
                <td class="value">100</td>
              </tr>
              <tr>
                <td class="title">生产中库存</td>
                <td class="value">50</td>
              </tr>
            </table>
          </el-tab-pane>
          <el-tab-pane label="单据明细" name="second">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
                :icon="activity.icon"
                :type="activity.type"
                :color="activity.color"
                :size="activity.size"
                :timestamp="activity.timestamp">
                {{activity.content}}
              </el-timeline-item>
            </el-timeline>
          </el-tab-pane>
        </el-tabs>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activeName:"first",
        dialogFormVisible:false,

        activities: [{
          content: '采购入库单(NBD-SS22072000022) 100PCS',
          timestamp: '2022-02-19',
          type: 'primary',
        }, {
          content: '采购订单(NBD-SS22072000022) 100PCS',
          timestamp: '2022-02-18',
          color: '#0bbd87',
          type: 'primary',
        }, {
          content: '销售出库单(NBD-SS22072000022) 100PCS',
          timestamp: '2022-01-20',
          type: 'primary',
        }, {
          content: '销售订单(NBD-SS22072000022) 100PCS',
          timestamp: '2022-01-18',
          type: 'primary',
        }]
      };
    }
  };
</script>


<style lang="less">
  .block{
    padding: 20px;

    table{
      width: 100%;
      border-collapse: collapse;
      border: 1px solid #ccc;
      td{
        padding: 10px 20px;
        font-size: 12px;
        border: 1px solid #ccc;
      }
      
      .title{
        font-weight: bold;
        color: black;
      }
    }
  }
</style>
